<template>
  <div class="wb-100 hb-100" :style="[bgStyle]">
    <div class="wb-100 hb-100 screen-contain">
      <div class="screen-title align-center row-center wb-100">
        <div class="title-text">博才咸嘉小学-智慧体育大课间</div>
        <img
          :src="imageUrl + 'screen_018.png'"
          alt=""
          class="control_icon"
          @click="handleScreen"
        />
      </div>
      <div class="screen-content wb-100 align-center">
        <div class="content-left">
          <div class="exercise-load">
            <div class="title_contain wb-100 space-between align-center">
              <div class="title">运动负荷</div>
              <div
                class="title_btn align-center row-center"
                @click="handleGoLoad"
              >
                <div class="btn_text">详情</div>
                <div class="btn_icon"></div>
              </div>
            </div>
            <div class="exercise-content align-center">
              <div
                class="exrtcise-item align-center column hb-100"
                v-for="(item, index) in sportsLoadList"
                :key="index"
              >
                <div class="num-content flex row-center item-num">
                  <span class="num">
                    <number-animation
                      :value="item.value"
                      :animation_time="1"
                      numType="num"
                      separator=""
                      class="item-num"
                    />
                  </span>
                  <span class="unit">人</span>
                </div>
                <div class="arrow mt-18 mb-10"></div>
                <div class="dec-contain align-center column">
                  <div class="name">{{ item.name }}</div>
                  <div class="dec">{{ item.dec }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="hb-2-54"></div>
          <div class="heart-rate-data">
            <div class="title_contain wb-100 space-between align-center">
              <div class="title">心率数据</div>
            </div>
            <div class="heart-centent">
              <div class="wb-100 hb-100 relative">
                <div class="wb-100 hb-100 mt-12 hidden" ref="tableBoxRef">
                  <div class="wb-100 hb-5 align-center">
                    <!-- 表头 -->
                    <div
                      class="center hb-100 fz-18 c-e0e9ef th"
                      v-for="(item, index) in columns"
                      :key="index"
                      :class="`wb-${Math.floor(100 / columns.length)}`"
                    >
                      {{ item.label }}
                    </div>
                  </div>
                  <div class="wb-100 hb-95 pt-10 pb-10">
                    <div class="wb-100 hb-100 auto">
                      <template v-if="dataList && dataList.length">
                        <TransitionGroup
                          name="fade"
                          class="relative wb-100 hb-100 fz-16"
                        >
                          <div
                            class="tr wb-100 h-40 fz-16 mb-6"
                            v-for="(item, index) in dataList"
                            :key="item.id"
                          >
                            <div class="center wb-100 hb-100">
                              <div
                                class="center td hb-100 c-e0e9ef fz-16"
                                :class="`wb-${
                                  Math.floor(100 / columns.length) || 32
                                }`"
                                v-for="(columns, j) in columns"
                                :key="j + index"
                              >
                                <div v-if="columns.prop == 'rank'" class="rank">
                                  TOP{{ item[columns.prop] }}
                                </div>
                                <div v-else-if="columns.prop == 'gender'">
                                  {{ ["女", "男"][item[columns.prop]] }}
                                </div>
                                <div v-else>
                                  {{ item[columns.prop] }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </TransitionGroup>
                      </template>

                      <div class="t-w fz-20 mt-30 center" v-else>暂无数据</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="view-content align-center">
            <div class="view-contain clip-path">
              <img src="@/assets/img/35.jpg" alt="" class="wb-100 hb-100" />
            </div>
            <div class="img-list align-center column pl-28">
              <div class="wb-100 img-contain clip-path">
                <img src="@/assets/img/35.jpg" alt="" class="wb-100 hb-100" />
              </div>
              <div class="hb-4-13"></div>
              <div class="wb-100 img-contain clip-path">
                <img src="@/assets/img/35.jpg" alt="" class="wb-100 hb-100" />
              </div>
              <div class="hb-4-13"></div>
              <div class="wb-100 img-contain clip-path">
                <img src="@/assets/img/35.jpg" alt="" class="wb-100 hb-100" />
              </div>
            </div>
          </div>
          <div class="right-bottom align-center pt-21">
            <div class="sport-indocators">
              <div
                class="title_contain_bottom wb-100 space-between align-center"
              >
                <div class="title">运动指标</div>
              </div>
              <div class="indocators-contain align-center flex-wrap">
                <div
                  class="align-center indocators-item"
                  v-for="(item, index) in sportIndicators"
                  :key="index"
                >
                  <div class="w-62 h-72">
                    <img
                      :src="imageUrl + item.img"
                      alt=""
                      class="wb-100 hb-100"
                    />
                  </div>
                  <div class="ml-12">
                    <div class="item-title">{{ item.title }}</div>
                    <div class="align-center">
                      <div class="fz-28 t-w tw-bold align-center">
                        <numberAnimation
                          :value="item.value"
                          :animation_time="1"
                          :numType="item.numType"
                          separator=","
                          class="fz-28 indocators-num"
                        />
                        <div
                          class="fz-14 item-unit ml-6"
                          v-if="item.unit && item.value"
                        >
                          {{ item.unit }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="average-rate">
              <div
                class="title_contain_bottom wb-100 space-between align-center"
              >
                <div class="title">平均心率</div>
              </div>
              <div class="average-contain">
                <div class="wb-100 hb-100" ref="averageLineRef"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imageUrl } from "@/common/common";
import numberAnimation from "@/components/numberAnimation";
import {
  sportsLoadListEnums,
  Accuracy_Ranking_Column,
  Sport_Indicators,
  Average_Hear_Rate_Line,
} from "./enums/index";
import { rand } from "@/utils/utils";
export default {
  components: {
    numberAnimation,
  },
  data() {
    return {
      imageUrl,
      isFull: false,
      studentList: [],
      school_id: 53,
      class_id: 808,
      schedule_id: 224446,
      studentsNumber: 1191,
      targetHeartTime: 0,
      centerLevel: {},
      centerTop: {},
      sportsLoad: {
        continuousCount: 0,
        warmCount: 0,
        extremeCount: 0,
      },
      sportsLoadList: sportsLoadListEnums,
      columns: Accuracy_Ranking_Column,
      dataList: [],
      sportIndicators: Sport_Indicators,
      averageHearRate: Average_Hear_Rate_Line,
      averageLine: null,
      timeInter: null,
    };
  },
  computed: {
    bgStyle() {
      return {
        background: "url(" + require("@/assets/img/35.jpg") + ")",
        backgroundSize: "100% 100%",
      };
    },
  },
  created() {
    this.getQuery();
  },
  mounted() {
    this.get_data();
    this.getHeart();
    clearInterval(this.timeInter);
    this.timeInter = setInterval(() => {
      this.getHeart();
      this.get_data();
    }, 5000);

    setTimeout(() => {
      this.getAverageCharts();
      this.WatchResize();
    }, 200);

    document.addEventListener("fullscreenchange", this.logFullScreen);
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.logFullScreen);
    clearInterval(this.timeInter);
  },
  methods: {
    getQuery() {
      const { school_id, class_id, total, schedule_id } = this.$route.query;
      this.school_id = school_id ? school_id : 53;
      this.class_id = class_id ? class_id : 808;
      this.studentsNumber = total ? total : 1191;
      this.schedule_id = schedule_id ? schedule_id : 224446;
    },
    async get_data() {
      const { school_id } = this;
      const res = await this.axios.get("courses/polling/" + school_id);
      if (res.data.code === 1) {
        const { data } = res.data;
        this.studentList = data.students.sort(
          (a, b) => b.heart_avg - a.heart_avg
        );
        this.dataList = data.students.map((item, index) => {
          return {
            ...item,
            rank: index + 1,
          };
        });
        this.centerLevel = data.centerLevel;
        this.centerTop = data.centerTop;

        this.setData();
      }
    },
    setData() {
      this.setExerciseLoad();

      const { centerLevel, centerTop, studentList } = this;

      // 靶心率
      let timeList = this.studentList.map((item, index) => {
        return item.time;
      });

      let time = Math.max(...timeList);

      if (time > 25 * 60) {
        time = 25 * 60;
      }

      this.targetHeartTime = rand(time * 0.69, time * 0.7);
      this.sportIndicators.forEach((item, index) => {
        switch (item.key) {
          case "participants":
            item.value = this.studentsNumber;
            break;
          case "averageRate":
            item.value = centerLevel.classes[0].heart_avg || 0;
            break;
          case "averageMaxRate":
            item.value = centerLevel.classes[0].heart_max || 0;
            break;
          case "averageDensity":
            item.value = centerTop.density || 0;
            break;
          case "averageIntensity":
            item.value = rand(60, 70);
            break;
          case "movementTime":
            item.value = this.targetHeartTime;
            break;
        }
      });
    },

    // 获取平均心率数据
    async getHeart() {
      const { school_id, class_id, schedule_id } = this;
      const res = await this.axios.get(
        "heart?schedule_id=" + schedule_id + "&class_id=" + class_id
      );
      if (res.status == 200 && res.data.code == 1) {
        const data = res.data.data;
        this.averageHearRate.xAxis.data = data.time.slice(-10);
        this.averageHearRate.series[0].data = data.all
          .map((item) => Number(item))
          .slice(-10);
        this.averageHearRate.series[1].data = data.boy
          .map((item) => Number(item))
          .slice(-10);
        this.averageHearRate.series[2].data = data.girl
          .map((item) => Number(item))
          .slice(-10);

        this.averageHearRate.series.forEach((item, index) => {
          if (item.data && item.data.length) {
            item.min = Math.min(...item.data);
          } else {
            item.min = 0;
          }
        });

        let minArr = this.averageHearRate.series.map((item) => item.min);

        let min = Math.min(...minArr);

        this.averageHearRate.yAxis.min = min;

        this.averageLine.setOption(this.averageHearRate);
      }
    },

    getAverageCharts() {
      const averageLineRef = this.$refs.averageLineRef;
      this.averageLine = this.$echarts.init(averageLineRef);
      this.averageLine.setOption(this.averageHearRate);
    },

    setExerciseLoad() {
      this.sportsLoad.warmCount = this.studentList.filter(
        (item) => item.heart_avg < 120
      ).length;
      this.sportsLoad.continuousCount = this.studentList.filter(
        (item) => item.heart_avg >= 120 && item.heart_avg <= 180
      ).length;
      this.sportsLoad.extremeCount = this.studentList.filter(
        (item) => item.heart_avg > 180
      ).length;

      this.sportsLoadList.forEach((item) => {
        Object.keys(this.sportsLoad).forEach((key) => {
          if (item.key === key) {
            item.value = this.sportsLoad[key];
          }
        });
      });
    },
    // 监控页面的大小变化
    WatchResize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.averageLine && that.averageLine.resize();
      });
    },
    handleGoLoad() {
      this.$router.push({
        path: "/exerciseLoad",
        query: {
          school_id: this.school_id,
          class_id: this.class_id,
          schedule_id: this.schedule_id,
        },
      });
    },

    handleScreen() {
      this.logFullScreen();
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.requestFullScreen();
      } else {
        this.exitFullscreen();
      }
    },

    requestFullScreen() {
      let de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    },

    exitFullscreen() {
      let de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    },

    logFullScreen() {
      if (document.fullscreenElement) {
        this.isFull = true;
      } else {
        this.isFull = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-num {
  font-family: "D-DIN-PRO-500-Medium", sans-serif;
}
.screen-contain {
  background: radial-gradient(rgba(10, 33, 47, 0.7) 0%, #0a212f 100%);
}
.screen-title {
  height: 12.5%;
  background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_002.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  .title-text {
    color: #00e5ff;
    font-size: 0.46rem;
    font-family: "ysbthzt", sans-serif;
  }
  .control_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-80%);
    width: 0.3rem;
    height: 0.3rem;
    cursor: pointer;
    right: 0.3rem;
  }
}

.screen-content {
  height: 87.5%;
  margin-top: -0.2rem;
  .content-left {
    width: 26.67%;
    height: 100%;
    padding-left: 0.28rem;
    .exercise-load {
      width: 100%;
      height: 40.57%;
      .exercise-content {
        width: 100%;
        height: calc(100% - 0.57rem);
        background-color: rgba(0, 250, 255, 0.2);
        position: relative;
        padding: 0 0.16rem 0.19rem;
        .exrtcise-item {
          width: 26.94%;
          display: flex;
          justify-content: flex-end;
          .num-content {
            width: 0.95rem;
            height: 0.94rem;
            background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_005.png")
              no-repeat;
            background-size: 100% 100%;
            font-size: 0.2rem;
            .unit {
              font-size: 0.18rem;
            }
          }
          .arrow {
            width: 0.32rem;
            height: 0.18rem;
            background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_008.png")
              no-repeat;
            background-size: 100% 100%;
          }
          .dec-contain {
            width: 1.3rem;
            height: 0.58rem;
            border-radius: 0.1rem;
            font-size: 0.18rem;
            background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_019.png")
              no-repeat;
            background-size: 100% 100%;

            .name {
              color: #fff;
            }

            .dec {
              color: rgba(255, 255, 255, 0.75);
              font-size: 0.16rem;
            }
          }
          &:nth-of-type(1) {
            .num-content {
              color: rgba(255, 164, 60, 1);
            }
          }
          &:nth-of-type(2) {
            width: 46.32%;
            justify-content: flex-start;
            .num-content {
              width: 1.5rem;
              height: 1.73rem;
              color: rgba(255, 255, 255, 1);
              font-size: 0.36rem;
              background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_006.png")
                no-repeat;
              background-size: 100% 100%;
              display: flex;
              align-items: center;
              padding-bottom: 0.6rem;
              .unit {
                font-size: 0.18rem;
              }
            }
            .arrow {
              width: 0.32rem;
              height: 0.35rem;
              background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_007.png")
                no-repeat;
              background-size: 100% 100%;
            }
          }
          &:nth-of-type(3) {
            .num-content {
              color: rgba(255, 117, 60, 1);
            }
          }
        }
      }
    }
    .hb-2-54 {
      height: 2.54%;
    }
    .heart-rate-data {
      width: 100%;
      height: 56.89%;
      .heart-centent {
        width: 100%;
        height: calc(100% - 0.57rem);
        background-color: rgba(0, 250, 255, 0.2);
        padding: 0.12rem 0.08rem;
      }
    }
  }

  .content-right {
    width: 73.33%;
    height: 100%;
    padding-right: 0.25rem;
    padding-left: 0.27rem;
    padding-top: 0.1rem;
    .view-content {
      width: 100%;
      height: 65.98%;
      background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_013.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 0.41rem 0.53rem 0.26rem 0.45rem;
      .view-contain {
        width: 76.55%;
        height: 100%;
        overflow: hidden;
      }
      .img-list {
        width: 23.45%;
        height: 100%;
        .img-contain {
          height: 30.57%;
        }
        .hb-4-13 {
          height: 4.13%;
        }
      }
    }
    .right-bottom {
      width: 100%;
      height: 34.02%;
      .sport-indocators {
        width: 50%;
        height: 100%;
        padding-right: 0.15rem;
        .indocators-contain {
          width: 100%;
          height: calc(100% - 0.57rem);
          background-color: rgba(0, 250, 255, 0.2);
          padding: 0.2rem 0.3rem;
          .indocators-item {
            width: 33%;
            height: 50%;
            .item-title {
              font-size: 0.16rem;
              color: rgba(180, 192, 204, 1);
              margin-bottom: 0.08rem;
              font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8",
                sans-serif;
            }
            .item-unit {
              font-size: 0.14rem;
              color: rgba(180, 192, 204, 1);
              font-weight: normal;
              font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8",
                sans-serif;
            }
            .indocators-num {
              font-family: "D-DIN-PRO-700-Bold", sans-serif;
            }
          }
        }
      }
      .average-rate {
        padding-left: 0.15rem;
        width: 50%;
        height: 100%;
        .average-contain {
          width: 100%;
          height: calc(100% - 0.57rem);
          background-color: rgba(0, 250, 255, 0.2);
        }
      }
    }
  }
}

.title_contain {
  height: 0.57rem;
  background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_009.png")
    no-repeat;
  background-size: 100% 100%;

  .title {
    color: #00e5ff;
    font-size: 0.22rem;
    padding: 0.08rem 0 0.15rem 0.47rem;
    font-family: "www.alltoall.net_notosansscmedium-4._2Pa03Y7x4t", sans-serif;
  }

  .title_btn {
    color: #00e5ff;
    font-size: 0.2rem;
    width: 0.93rem;
    height: 0.28rem;
    background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_003.png")
      no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    .btn_text {
      font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
    }
    .btn_icon {
      width: 0.17rem;
      height: 0.12rem;
      margin-left: 0.03rem;
      background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_004.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}

.title_contain_bottom {
  height: 0.57rem;
  background: url("https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/prod/pic/big_screen/screen_020.png")
    no-repeat;
  background-size: 100% 100%;

  .title {
    color: #00e5ff;
    font-size: 0.22rem;
    padding: 0.08rem 0 0.15rem 0.47rem;
    font-family: "www.alltoall.net_notosansscmedium-4._2Pa03Y7x4t", sans-serif;
  }
}

/* 1. 声明过渡效果 */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. 声明进入和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(0.3rem, 0);
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.fade-leave-active {
  position: absolute;
}

.th {
  font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
}

.tr {
  background: linear-gradient(90deg, #102e40 0%, #104250 100%);
  font-family: "www.alltoall.net_notosanssc-6._Rh2tKdUFz8", sans-serif;
  .rank {
    font-size: 0.2rem;
    font-family: ysbthzt;
    font-weight: bold;
    background: linear-gradient(#96b1bf 0%, #7a98a3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:nth-of-type(1) {
    background: linear-gradient(90deg, #232503 0%, #073251 100%);
    .rank {
      background: linear-gradient(#dde2ce 0%, #e1db6f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &:nth-of-type(2) {
    background: linear-gradient(90deg, #122246 0%, rgba(6, 23, 35, 0.8) 100%);
    .rank {
      background: linear-gradient(#bad5e9 0%, #bad5e9 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &:nth-of-type(3) {
    background: linear-gradient(90deg, #102e40 0%, rgba(3, 8, 37, 0.8) 100%);
    .rank {
      background: linear-gradient(#e3a9a4 0%, #e1694a 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.tr:nth-child(2n) {
  // background-color: #293c82;
}

.c-e0e9ef {
  color: #e0e9ef;
}

.clip-path {
  -webkit-clip-path: polygon(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 99%,
    0 95%,
    0 5%
  );
  clip-path: polygon(
    5% 0,
    95% 0,
    100% 5%,
    100% 95%,
    95% 100%,
    5% 99%,
    0 95%,
    0 5%
  );
}
</style>
